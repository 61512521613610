import React from 'react';
import logo from './logo.svg';
import './App.css';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      testUrl: null,
      testEndpoint: null,
      testMailAddress: null
    }

    this.getRequestBinLink();
  }

  async getRequestBinLink() {
    var response = await fetch("https://requestbin-api.pipedream.com/api/v2/http_endpoints", {
      "method": "POST"
    });

    const json = await response.json();

    console.log(json)

    var testEndpoint = `https://${json.data.api_key}.x.pipedream.net/mail`;

    this.setState({
      testUrl: `https://requestbin.com/r/${json.data.api_key}`,
      testEndpoint: testEndpoint,
      testMailAddress: `${btoa(testEndpoint)}@mail2api.io`
    });
  }

  renderTestItout() {
    if(!this.state.testUrl) return "";

    return (
      <div>
        <h3>Test it out!</h3>
        <div>
          <p>Your endpoint: <code>{this.state.testEndpoint}</code></p>
          <p>Send an e-mail to: <code>{this.state.testMailAddress}</code></p>
          <a className="button" href={this.state.testUrl} target="_blank">Watch it on requestbin.com</a>
        </div>
        <iframe className="hidden-iframe" src={this.state.testUrl} title="requestbin"></iframe>
        <br />
      </div>
    );
  }

  render() {
    return (
      <div className="App">
        <h1>mail2api.io</h1>
        <p className="subtitle">Converts any mail into an API call</p>
        <br />
        <div>
          <p><b>Step 1:</b> Convert your API endpoint to base64</p>
          <p><b>Step 1:</b> Send an e-mail to <code>&lt;base64 API endpoint&gt;@mail2api.io</code></p>
          <p><b>Step 3:</b> Watch your endpoint for incoming POST requests</p>
        </div>
        <br />
        {this.renderTestItout()}
        <br />
      </div>
    );
  }
}

export default App;
